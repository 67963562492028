* {
    padding: 0px;
    margin: 0px;
}

.c {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 930px;
    margin-bottom: 1rem;
}